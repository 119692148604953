import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Dotdotdot from "components/Common/Dotdotdot";
import PodcastCard from "components/Entities/Cards/PodcastCards/PodcastCardContainerAsync";
import EntityImage from "components/Entities/EntityImage";
import entityList from "components/Entities/EntityList";
import EntityFollowButton from "components/Follow/EntityFollowButtonAsync";

import HomepageFeedPlaceholder from "./HomepageFeedPlaceholder";

import modalActions from "actions/modals";
import * as sortConstants from "constants/sort";
import loadCreatorsList from "sagas/pagination/lists/loadCreatorsList";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import { selectSpecificCreator } from "selectors/creator";
import { selectSpecificPodcast } from "selectors/podcast";
import getCreatorName from "utils/entity/getCreatorName";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useTimelineAction from "hooks/useTimelineAction";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const MAX_PODCASTS = 12;
const MAX_CREATORS = 12;

const baseStyles = {
  headerTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    marginBottom: "1.1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.5rem",
    },
  },
  headerLink: {
    cursor: "pointer",
    color: colours.primary,
  },
  sectionDivider: {
    margin: "2rem 0",
    width: "100%",
    borderBottom: "1px #dfe1e6 solid",
  },
  sectionTitle: {
    ...gStyles.avalonBold,
    fontSize: ".875rem",
    color: colours.black,
    marginBottom: "2rem",
    width: "100%",
    textAlign: "center",
  },
  suggestionsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  buttonContainer: {
    width: "100%",
  },
  creatorsContainer: {
    margin: "0 .5rem",

    [ScreenSizes.lgAndAbove]: {
      margin: "0 -0.5rem",
    },
  },
  creatorContainer: {
    width: "100%",
    margin: "0 0 2.5rem",

    [ScreenSizes.smAndAbove]: {
      width: "50%",
    },
    [ScreenSizes.mdAndAbove]: {
      width: "33.3333%",
    },
    [ScreenSizes.xxlAndAbove]: {
      width: "20%",
    },
  },
  creatorContainer10: {
    [ScreenSizes.xxlAndAbove]: {
      display: "none",
    },
  },
  creatorContainer11: {
    [ScreenSizes.xxlAndAbove]: {
      display: "none",
    },
  },
  creatorInner: {
    padding: "0 .5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  creatorImage: {
    marginBottom: "1rem",
    width: "90%",
    height: "auto",
  },
  creatorName: {
    ...gStyles.avalonBold,
    ...gStyles.textEllipsis,
    maxWidth: "100%",
    color: colours.black,
    fontSize: ".875rem",
    marginBottom: ".5rem",
  },
  creatorSubtitle: {
    ...gStyles.fontRegular,
    maxWidth: "100%",
    color: colours.black,
    fontSize: ".7rem",
    marginBottom: "1.375rem",
    textAlign: "center",
    minHeight: "3.8em",
  },
  elseSectionTitle: {
    marginTop: "2rem",
  },
  elseSectionDescription: {
    ...gStyles.fontRegular,
    fontSize: ".875rem",
    color: colours.black,
    lineHeight: "27px",
    maxWidth: "30.75rem",
    padding: "0 1rem",
    textAlign: "center",
    margin: "0 auto 4rem",
  },
};

const followButtonStyles = {
  button: {
    padding: ".4rem 0",
  },
};

const PopularPodcastsList = entityList({
  key: "homepageView/tryThesePodcasts",
  sort: sortConstants.SORT_ORDER_CURATED,
  list_type: "podcasts_filtered",
  loadListAction: loadPodcastsList,
  entity_type: "podcast",
  filters: { in_curated_list: { value: "onboarding_podcasts" } },
  pageSize: MAX_PODCASTS,
  getEntity: (state, id) => selectSpecificPodcast(state, id),
});

const PopularCreatorsList = entityList({
  key: "homepageView/tryTheseCreator",
  sort: sortConstants.SORT_ORDER_RECENT_WEIGHTED,
  list_type: "creators_filtered",
  loadListAction: loadCreatorsList,
  entity_type: "creator",
  pageSize: MAX_CREATORS,
  staticFilters: { profile_image: { value: true } },
  getEntity: (state, id) => selectSpecificCreator(state, id),
});

const podcastStyles = {
  podcastContainer: {
    margin: "0 0 2.5rem",

    [ScreenSizes.smAndAbove]: {
      width: "50%",
    },
    [ScreenSizes.mdAndAbove]: {
      width: "33.3333%",
    },
    [ScreenSizes.xxlAndAbove]: {
      width: "20%",
    },
  },
  podcastInner: {
    padding: "0 0.5rem",
  },
  podcastImage: {
    maxWidth: "280px",
  },
};

const HomepageFeedNotFollowingAnything = (props) => {
  const {
    renderHeader: passedRenderHeader,
    placeholderStyles,
    timelineKey,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrMore } = useWindowSize();

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });
  const {
    actions: { loadTimeline },
    timelineModuleLoaded,
  } = useTimelineAction();
  const handleTriggerOnboardingModal = useCallback(
    () => showModal("onboarding"),
    [showModal]
  );

  const handleFollow = useCallback(() => {
    if (timelineModuleLoaded) {
      loadTimeline({ timelineKey });
    }
  }, [loadTimeline, timelineKey, timelineModuleLoaded]);

  const renderHeader = () => (
    <Fragment>
      <div className={css(styles.headerTitle)}>
        <span
          className={css(styles.headerLink)}
          onClick={handleTriggerOnboardingModal}
        >
          Activate your Feed
        </span>{" "}
        by following Podcasts, Creators, Lists, and other Users!
      </div>
      <div>
        New releases, episode reviews, guest credits, and personalized
        recommendations will begin to appear in your Feed.
      </div>
    </Fragment>
  );

  const renderPodcast = (podcast, index) => {
    if (isWindowSizeOrMore("xxlarge") && index > 9) {
      return null;
    }

    return (
      <PodcastCard
        key={index}
        entity={podcast}
        layout="follow"
        styles={podcastStyles}
        onClick={handleFollow}
        renderOnMount
      />
    );
  };

  const renderCreator = (creator, index) => {
    const creatorName = getCreatorName(creator);
    return (
      <div
        key={creator.get("id")}
        className={css(
          styles.creatorContainer,
          styles[`creatorContainer${index}`]
        )}
      >
        <div className={css(styles.creatorInner)}>
          <div className={css(styles.creatorImage)}>
            <EntityImage entity={creator} entity_type="creator" size={218} />
          </div>
          <div className={css(styles.creatorName)} title={creatorName}>
            {creatorName}
          </div>
          <div
            className={css(styles.creatorSubtitle)}
            title={creator.get("subtitle_short")}
          >
            {creator.get("subtitle_short") && (
              <Dotdotdot clamp={3} useNativeClamp>
                {creator.get("subtitle_short")}
              </Dotdotdot>
            )}
          </div>
          <div className={css(styles.buttonContainer)}>
            <EntityFollowButton
              entity={creator}
              customStyles={followButtonStyles}
              onClick={handleFollow}
              entity_type="creator"
              variation="white"
              fontSize=".75rem"
              flat
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <HomepageFeedPlaceholder
      header={passedRenderHeader ? passedRenderHeader() : renderHeader()}
      styles={placeholderStyles}
    >
      <div className={css(styles.sectionTitle)}>
        Not sure who to follow? Try these podcasts...
      </div>
      <div
        className={css(styles.suggestionsContainer, styles.podcastsContainer)}
      >
        <PopularPodcastsList renderItem={renderPodcast} />
      </div>
      <div className={css(styles.sectionDivider)} />
      <div className={css(styles.sectionTitle)}>... and these creators.</div>
      <div
        className={css(styles.suggestionsContainer, styles.creatorsContainer)}
      >
        <PopularCreatorsList renderItem={renderCreator} />
      </div>
      <div className={css(styles.sectionDivider)} />
      <div className={css(styles.sectionTitle, styles.elseSectionTitle)}>
        Or maybe something else...
      </div>
      <div className={css(styles.elseSectionDescription)}>
        You can explore our full database by searching for your favorite
        podcasts, creators or topics at the top of the page.
      </div>
    </HomepageFeedPlaceholder>
  );
};

HomepageFeedNotFollowingAnything.propTypes = {
  timelineKey: PropTypes.string,
  renderHeader: PropTypes.func,
  placeholderStyles: PropTypes.object,
};

HomepageFeedNotFollowingAnything.defaultProps = {
  renderHeader: null,
  placeholderStyles: null,
  timelineKey: null,
};

export default memo(HomepageFeedNotFollowingAnything);
