import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  homepageFeedPlaceholder: {
    display: "flex",
    flexDirection: "column",
    margin: "-1rem -2rem 0",
  },
  header: {
    ...gStyles.fontRegular,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#fff",
    border: "1px solid rgb(239, 239, 239)",
    padding: "1.5rem 2rem",
    width: "100%",
    margin: "0",
    fontSize: ".9rem",
    lineHeight: "29px",
    color: colours.black,

    [ScreenSizes.smAndAbove]: {
      padding: "1.5rem 2.3rem",
    },
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
      padding: "2.375rem 5rem",
    },
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      margin: 0,
      padding: "2.375rem 6.25rem",
    },
  },
  headerDivider: {
    width: "100%",
    margin: "2rem 0",
    borderBottom: "1px #dfe1e6 solid",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0 1rem",

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
  },
};

const HomepageFeedPlaceholder = (props) => {
  const { header, children } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.homepageFeedPlaceholder)}>
      {header && <div className={css(styles.header)}>{header}</div>}
      <div className={css(styles.contentContainer)}>{children}</div>
    </div>
  );
};

HomepageFeedPlaceholder.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};

HomepageFeedPlaceholder.defaultProps = {
  header: null,
  children: null,
};

export default HomepageFeedPlaceholder;
